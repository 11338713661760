import React, { useState } from "react";
import classes from "./CreateData.module.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";

const CreateData = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const [credentials, setcredentials] = useState({
    name: "",
    phone_number: "",
    mensuration_date: "",
  });

  const CreateDataHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/createData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: credentials.name,
            phone_number: credentials.phone_number,
            mensuration_date: credentials.mensuration_date,
          }),
        }
      );
      const json = await response.json();
      console.log(json);

      if (!json.success) {
        alert("Enter valid credentials");
      }
      if (json.success) {
        navigate("/home", { state: { isAuthenticated: true } });
        setcredentials({
          name: "",
          phone_number: "",
          mensuration_date: "",
        });
        alert("Created Sucessfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (event) => {
    setcredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  const navigateHandler = (e) => {
    e.preventDefault();
    navigate("/home", { state: { isAuthenticated: true } });
  };
  return (
    <>
      <Navbar />
      <div className={classes.container}>
        <div className={classes["login_form_container"]}>
          <div className={classes["login_form"]}>
            <h2>Create Data</h2>
            <div className={classes["input_group"]}>
              <i className={classes["fa fa-user"]}></i>
              <input
                type="text"
                placeholder="Full Name"
                className={classes["input_text"]}
                autoComplete="off"
                name="name"
                value={credentials.name}
                onChange={onChange}
              />
            </div>
            <div className={classes["input_group"]}>
              <i className={classes["fa fa-user"]}></i>
              <input
                type="text"
                placeholder="Phone Number"
                name="phone_number"
                value={credentials.phone_number}
                className={classes["input_text"]}
                autoComplete="off"
                onChange={onChange}
              />
            </div>
            <div className={classes["input_group"]}>
              <i className="fa fa-unlock-alt"></i>
              <input
                type="text"
                placeholder="Mensuration Date"
                name="mensuration_date"
                value={credentials.mensuration_date}
                className={classes["input_text"]}
                autoComplete="off"
                onChange={onChange}
              />
            </div>
            <div
              className={classes["button_group"]}
              id={classes["login_button"]}
            >
              <p onClick={CreateDataHandler}>Create Data</p>
            </div>
            <div className={classes["fotter"]}>
              <p onClick={navigateHandler}>See Data</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateData;
