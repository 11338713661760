import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

import classes from "./Dashboard.module.css";
import BarChart from "../Chart/Barchart";
import CircularChart from "../Chart/CircularChart";
import LinearChart from "../Chart/LinearChart";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("days");

  const [totalPatients, setTotalPatients] = useState(null);
  const [totalMessagesSent, setTotalMessagesSent] = useState(0);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "no of patients",
        data: [],
        backgroundColor: ["#007bff", "#28a745", "#fd7e14"],
      },
    ],
  });
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleNavigation = () => {
    navigate("/createData", { state: { isAuthenticated: true } });
  };
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const fetchData = async (option) => {
    try {
      let response = null;

      // Choose the appropriate endpoint based on the selected option
      if (option === "years") {
        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/dataByYear`
        ); // Replace with your API endpoint
      } else if (option === "months") {
        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/dataByMonth`
        ); // Replace with your API endpoint
      } else if (option === "days") {
        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/dataByDays`
        ); // Replace with your API endpoint
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Update chart data based on the fetched data
      setChartData({
        labels: data.map((item) => {
          if (option === "years") {
            return String(item._id.year);
          } else if (option === "months") {
            const monthName = monthNames[item._id.month - 1]; // Adjust for 0-based index
            return monthName;
          } else if (option === "days") {
            const monthName = monthNames[item._id.month - 1];
            return `${item._id.day} ${monthName}`;
          } else {
            return 0;
          }
        }),
        datasets: [
          {
            label: "no of patients",
            data: data.map((item) => item.count),
            backgroundColor: ["#007bff", "#28a745", "#fd7e14"],
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Initial data update based on the default selected option
    fetchData(selectedOption);
    console.log("fetch");
  }, [selectedOption]);

  // Total patients
  useEffect(() => {
    const fetchAdditionalData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/totalPatients`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setTotalPatients(data.totalPatients);
      } catch (error) {
        console.error("Error fetching additional data:", error);
      }
    };

    fetchAdditionalData();
  }, []);

  useEffect(() => {
    const fetchTotalMessagesSent = async () => {
      try {
        // Make an HTTP GET request to your backend API endpoint
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/totalMessages`
        );

        if (!response.ok) {
          throw new Error("HTTP error! Status: " + response.status);
        }

        const data = await response.json();
        setTotalMessagesSent(data.totalMessagesSent);
      } catch (error) {
        console.error("Error fetching total messages:", error);
      }
    };

    fetchTotalMessagesSent();
  }, []);

  const totalAmount = 10000;
  const canSendMessage = Math.floor(totalAmount / 1.3);
  const messageSentPercentage = Math.floor(
    (totalMessagesSent / canSendMessage) * 100
  );
  const cost = 1.3;
  let moneyExpensesPercentage =
    Math.floor((totalMessagesSent * 1.3) / totalAmount) * 100;

  console.log(messageSentPercentage);

  // let messageLeft = Math.floor(1000 - moneyExpensesPercentage) / 1.3;
  // let messageLeftPercentage = Math.round((messageLeft / 1000) * 100);

  return (
    <>
      <Navbar handleNavigation={handleNavigation} />
      <div className={classes.dashboard_container}>
        <div className={classes.top}>
          <div className={classes.top_left}>
            <div className={classes.innerdiv}>
              <div className={classes.svg}>
                <HomeOutlinedIcon />
              </div>
              <p>
                <span>/ </span>Dashboard
              </p>
            </div>
            <div className={classes.innerdiv}>
              <div className={classes.svg}>
                <DashboardOutlinedIcon />
              </div>
              <h3>Minimal Dashboard</h3>
            </div>
          </div>
        </div>
        {/* Middle */}
        <div className={classes.middle}>
          <div className={classes.info_container}>
            <div className={classes.card}>
              <div className={classes.card_left}>
                <p style={{ color: "green" }}>Total Patients</p>
              </div>
              <div className={classes.card_right}>
                <h1 style={{ color: "green" }}>{totalPatients}</h1>
                {/* <CircularChart
                  height={50}
                  width={50}
                  value={75}
                  color={"green"}
                /> */}
              </div>
            </div>
            <div
              className={classes.card}
              style={{ borderBottom: "4px solid blue" }}
            >
              <div className={classes.card_left}>
                <p style={{ color: "blue" }}>Total messages sent</p>
              </div>
              <div className={classes.card_right}>
                <h1 style={{ color: "blue" }}>{totalMessagesSent}</h1>
                <CircularChart
                  height={50}
                  width={50}
                  value={messageSentPercentage}
                  color={"blue"}
                />
              </div>
            </div>
            <div
              className={classes.card}
              style={{ borderBottom: "4px solid red" }}
            >
              <div className={classes.card_left}>
                <p style={{ color: "red" }}>Money Expenses</p>
              </div>
              <div className={classes.card_right}>
                <h1 style={{ color: "red" }}>{totalMessagesSent * cost}</h1>
                <CircularChart
                  height={50}
                  width={50}
                  value={moneyExpensesPercentage}
                  color={"red"}
                />
              </div>
            </div>
            <div
              className={classes.card}
              style={{ borderBottom: "4px solid red" }}
            >
              <div className={classes.card_left}>
                <p style={{ color: "red" }}>Message left</p>
              </div>
              <div className={classes.card_right}>
                <h1 style={{ color: "red" }}>
                  {canSendMessage - totalMessagesSent}
                </h1>
                <CircularChart
                  height={50}
                  width={50}
                  value={100 - messageSentPercentage}
                  color={"red"}
                />
              </div>
            </div>
          </div>
          <div className={classes.charbar_container}>
            <div className={classes.barchart}>
              <div className={classes.inner_chart}>
                <p>no of patients</p>
                <div className={classes.select}>
                  <select
                    id="mySelect"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="months">Months</option>
                    <option value="days">Days</option>
                    <option value="years">Years</option>
                  </select>
                </div>
              </div>
              <div className={classes.barchartContainer}>
                <BarChart data={chartData} />
              </div>
            </div>
            <div className={classes.pieChart_container}>
              <div className={classes.pieChart}>
                <h1 style={{ color: "yellow" }}>Message Used</h1>
                <div className={classes.line} />
                <CircularChart
                  height={200}
                  width={200}
                  value={messageSentPercentage}
                  color={"yellow"}
                />
              </div>
              <div className={classes.linear_chart}>
                <p style={{ color: "yellow" }}>message left</p>
                <LinearChart value={100 - messageSentPercentage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
