import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import LoginPage from "./Screen/LoginPage";
import SignupPage from "./Screen/SignupPage";
import HomePage from "./Screen/HomePage";
import CreateData from "./Screen/CreateData";
import Dashboard from "./Components/Dashboard";

function App() {
  const location = useLocation();
  const isAuthenticated = location.state?.isAuthenticated || false;
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage />} />
        {isAuthenticated ? <Route path="/home" element={<HomePage />} /> : null}
        {isAuthenticated ? (
          <Route path="/createData" element={<CreateData />} />
        ) : null}
        {/* {isAuthenticated ? (
          <Route path="/createuser" element={<SignupPage />} />
        ) : null} */}
        {isAuthenticated ? (
          <Route path="/dashboard" element={<Dashboard />} />
        ) : null}
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
      </Routes>
    </div>
  );
}

export default App;
