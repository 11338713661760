import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const CircularChart = (props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={props.height}
      width={props.width}
      position="relative"
    >
      <CircularProgress
        variant="determinate"
        value={props.value}
        thickness={3}
        style={{
          width: props.width,
          height: props.height,
          color: props.color,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          width: "100%",
          color: `${props.color}`,
        }}
      >
        {props.value}%
      </div>
    </Box>
  );
};

export default CircularChart;
