import { useNavigate } from "react-router-dom";
import momcheckup from "../../src/momcheckup.png";
import "./Navbar.css";

const Navbar = ({ handleNavigation, handleSearch }) => {
  const navigate = useNavigate();
  const handleDashboard = () => {
    navigate("/dashboard", { state: { isAuthenticated: true } });
  };
  return (
    <div className="nav-container">
      <div className="left">
        <h2
          onClick={() => {
            navigate("/home", { state: { isAuthenticated: true } });
          }}
        >
          <img src={momcheckup} alt="MomCheckup" height={100} width={100} />
        </h2>
      </div>
      <div className="right">
        <ul>
          <li onClick={handleDashboard}>Dashbord</li>
          <li onClick={handleNavigation}>CreateData</li>
          <li onClick={handleSearch}>Search</li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;

// const Navbar = () => {
//   const navigate = useNavigate();
//   const [search,setSearch] = useState(false)

//   const handleNavigation = () => {
//     navigate("/createData", { state: { isAuthenticated: true } });
//   };
//   const handleSearch =() =>{
//     setSearch(true);
//   }
//   return (
//     <div className="nav-container">
//       <div className="left">
//         <h2>momsCheckUps</h2>
//       </div>
//       <div className="right">
//         <ul>
//           <li>Dashbord</li>
//           <li onClick={handleNavigation}>CreateData</li>
//           <li onClick={handleSearch}>Search</li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Navbar;
