import React, { useState } from "react";
import classes from "./LoginPage.module.css";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  console.log(process.env.REACT_APP_API_ENDPOINT);
  console.log("hello");
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [credentials, setcredentials] = useState({
    email: "",
    password: "",
  });

  const LoginHandler = async (e) => {
    e.preventDefault();

    try {
      console.log("Sending request with data:", credentials);
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Accept: "application/json",
        },
        body: JSON.stringify({
          email: credentials.email,
          password: credentials.password,
        }),
      });

      if (!response.ok) {
        // Handle non-200 status codes (e.g., 400, 404, 500) here
        console.error(`HTTP Error: ${response.status}`);
        return;
      }

      const json = await response.json();
      console.log(json);

      if (json.success) {
        localStorage.setItem("authToken", json.authToken);
        console.log(localStorage.getItem("authToken"));

        setIsAuthenticated(true);
        navigate("/home", { state: { isAuthenticated: true } });

        setcredentials({
          name: "",
          email: "",
          password: "",
        });
      } else {
        alert("Enter valid credentials");
      }
    } catch (error) {
      // Handle network errors or other exceptions here
      console.error("An error occurred:", error);
    }
  };

  const onChange = (event) => {
    setcredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className={classes.center}>
      <div className={classes["login_form_container"]}>
        <div className={classes["login_form"]}>
          <h2>Login</h2>
          <div className={classes["input_group"]}>
            <i className={classes["fa fa-user"]}></i>
            <input
              type="text"
              placeholder="Username"
              className={classes["input_text"]}
              autoComplete="off"
              name="email"
              value={credentials.email}
              onChange={onChange}
            />
          </div>
          <div className={classes["input_group"]}>
            <i className="fa fa-unlock-alt"></i>
            <input
              type="password"
              placeholder="Password"
              className={classes["input_text"]}
              autoComplete="off"
              name="password"
              value={credentials.password}
              onChange={onChange}
            />
          </div>
          <div className={classes["button_group"]} id={classes["login_button"]}>
            <p onClick={LoginHandler}>Submit</p>
          </div>
          {/* <div className={classes["fotter"]}>
            <p onClick={navigateHandler}>SignUp</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
