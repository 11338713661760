import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const LinearChart = (props) => {
  return (
    <Box width={200} position="relative">
      <LinearProgress
        variant="determinate"
        value={props.value}
        thickness={20}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "right",
          marginLeft: "50px",
          width: "100%",
          color: "yellow",
        }}
      >
        {props.value}%
      </div>
    </Box>
  );
};

export default LinearChart;
