import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const BarChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return;

    // Check if there's a previous chart instance and destroy it
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    const newChartInstance = new Chart(ctx, {
      type: "bar",
      data: data,
    });

    // Store the new chart instance for future reference
    chartInstanceRef.current = newChartInstance;
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default BarChart;
