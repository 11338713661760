import React, { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchIcon from "@mui/icons-material/Search";
import "./HomePage.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import PatientDetailsPortal from "./PatientsDetailPortal";

const HomePage = () => {
  const [patientData, setPatientData] = useState([]);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isPortalOpen, setIsPortalOpen] = useState(false);

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const fetchData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    // Format the date fields before setting them in the state
    const formattedData = data.reverse().map((patient) => ({
      ...patient,
      registered_date: formatDate(patient.registered_date),
      mensuration_date: formatDate(patient.mensuration_date),
    }));

    setPatientData(formattedData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteHandler = async (id) => {
    console.log(id);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/delete/${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.status === 200) {
        fetchData();
      } else {
        console.error("Failed to delete data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onChange = (event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);

    // Apply the filter logic to the original patientData
    const filteredResults = patientData.filter((patient) =>
      patient.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredResults);
  };

  const handleNavigation = () => {
    navigate("/createData", { state: { isAuthenticated: true } });
  };

  const handleSearch = () => {
    setIsSearch(!isSearch);
  };

  const handleRowClick = (patient) => {
    setSelectedPatient(patient);
    setIsPortalOpen(true);
  };

  const handleClosePortal = () => {
    setSelectedPatient(null);
    setIsPortalOpen(false);
  };

  return (
    <>
      <div className="container">
        <Navbar
          handleNavigation={handleNavigation}
          handleSearch={handleSearch}
        />

        <div className="center">
          {isSearch ? (
            <div className="input_group">
              <input
                type="text"
                placeholder="name"
                className="input_text"
                autoComplete="off"
                name="search"
                value={search}
                onChange={onChange}
              />

              <div className="icon">
                <SearchIcon />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="login_form_container">
            <div className="login_form">
              <div className="table__body">
                <table>
                  <thead>
                    <tr>
                      <th> Id</th>
                      <th> Patients</th>
                      <th>Phone Number</th>
                      <th>Registered Date</th>
                      <th>Mensuration Date</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {search !== ""
                      ? filteredData.map((patient, i) => (
                          <React.Fragment key={patient._id}>
                            <tr>
                              <td>{i + 1}</td>
                              <td
                                onClick={() => handleRowClick(patient)}
                                className="name"
                              >
                                {patient.name}
                              </td>
                              <td> {patient.phone_number}</td>
                              <td> {patient.registered_date}</td>
                              <td> {patient.mensuration_date}</td>
                              <td
                                onClick={() => deleteHandler(patient._id)}
                                className="delete__btn name"
                              >
                                <DeleteOutlineIcon />
                              </td>
                            </tr>
                            <tr className="divider">
                              <td colSpan="6">
                                <div></div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))
                      : patientData.map((patient, i) => (
                          <React.Fragment key={patient._id}>
                            <tr>
                              <td>{i + 1}</td>
                              <td
                                onClick={() => handleRowClick(patient)}
                                className="name"
                              >
                                {patient.name}
                              </td>
                              <td> {patient.phone_number}</td>
                              <td> {patient.registered_date}</td>
                              <td> {patient.mensuration_date}</td>
                              <td
                                onClick={() => deleteHandler(patient._id)}
                                className="delete__btn name"
                              >
                                <DeleteOutlineIcon />
                              </td>
                            </tr>
                            <tr className="divider">
                              <td colSpan="6">
                                <div></div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="portal">
        {isPortalOpen && selectedPatient && (
          <PatientDetailsPortal
            patient={selectedPatient}
            onClose={handleClosePortal}
          />
        )}
      </div>
    </>
  );
};

export default HomePage;
