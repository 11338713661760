import React from "react";
import { createPortal } from "react-dom";
import "./PatientsDetailPortal.css";

const portalRoot = document.getElementById("portal-root");

const PatientDetailsPortal = ({ patient, onClose }) => {
  return createPortal(
    <div className="portal-overlay">
      <div className="patient-details-portal">
        <div className="patient-details-container">
          <button className="close-button" onClick={onClose}>
            Close
          </button>
          <h2>Patient Details</h2>
          <p>Name: {patient.name}</p>
          <p>Phone Number: {patient.phone_number}</p>
          <p>Registered Date: {patient.registered_date}</p>
          <p>Mensuration Date: {patient.mensuration_date}</p>
        </div>
      </div>
    </div>,
    portalRoot
  );
};

export default PatientDetailsPortal;
